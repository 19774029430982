import React from 'react';

import GamesItem from './GamesItem';

const GamesList = ({
  authUser,
  messages,
  onEditMessage,
  onRemoveMessage,
}) => (
  <div className="game-main">
    {messages.map(message => (
      <GamesItem
        authUser={authUser}
        key={message.uid}
        message={message}
        onEditMessage={onEditMessage}
        onRemoveMessage={onRemoveMessage}
      />
    ))}
  </div>
);

export default GamesList;
