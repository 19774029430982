import React from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../components/Session';
import Layout from '../components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import Messages from '../components/Messages';
import Games from '../components/GameAdmin';
import Payment from '../components/Payment';
import * as ROLES from '../constants/roles';

const HomePageBase = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
    <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p>
{!!authUser.roles[ROLES.ADMIN] && (
    <Messages />
  )}
  {!!authUser.roles[ROLES.ADMIN] && (
      <Games />
    )}
  {!!authUser.roles[ROLES.ADMIN] && (
      <Payment />
    )}
  </div>
);

const NavigationNonAuth = () => (
  <div>
    <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default () => (
  <Layout>
    <HomePageBase />
  </Layout>
);
