import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import MessageList from './MessageList';
// import 'firebase/storage';

// import { firestore } from './plugins/firebase';

class Messages extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      url: '',
      urltext: '',
      url2: '',
      urltext2: '',
      loading: false,
      messages: [],
      limit: 6,
      curTime : new Date().toISOString().substring(0,10),
      image: '',
      recieveImg: '',
      category: '',
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.props.firebase
      .messages()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const messageObject = snapshot.val();

        if (messageObject) {
          const messageList = Object.keys(messageObject).reverse().map(key => ({
            ...messageObject[key],
            uid: key,
          }));

          this.setState({
            messages: messageList,
            loading: false,
          });

          if(this.state.messages.length >= 1){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[0].image}`).getDownloadURL().then((url) => {
            this.state.messages[0].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[0].text = this.state.messages[0].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 2){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[1].image}`).getDownloadURL().then((url) => {
            this.state.messages[1].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[1].text = this.state.messages[1].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 3){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[2].image}`).getDownloadURL().then((url) => {
            this.state.messages[2].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[2].text = this.state.messages[2].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 4){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[3].image}`).getDownloadURL().then((url) => {
            this.state.messages[3].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[3].text = this.state.messages[3].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 5){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[4].image}`).getDownloadURL().then((url) => {
            this.state.messages[4].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[4].text = this.state.messages[4].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 6){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[5].image}`).getDownloadURL().then((url) => {
            this.state.messages[5].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[5].text = this.state.messages[5].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.messages().off();
  }

  onChangeText1 = event => {
    this.setState({ text1: event.target.value });
  };

  onChangeText2 = event => {
    this.setState({ text2: event.target.value });
  };

  onChangeText3 = event => {
    this.setState({ text3: event.target.value });
  };

  onChangeText4 = event => {
    this.setState({ text4: event.target.value });
  };

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  onChangeUrl = event => {
    this.setState({ url: event.target.value });
  };

  onChangeUrltext = event => {
    this.setState({ urltext: event.target.value });
  };

  onChangeUrl2 = event => {
    this.setState({ url2: event.target.value });
  };

  onChangeUrltext2 = event => {
    this.setState({ urltext2: event.target.value });
  };

  onChangeImage = event => {
    this.setState({ image: event.target.files[0] });
  };

  onChangeCategory = event => {
    this.setState({ category: event.target.category });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().push({
      text1: this.state.text1,
      text2: this.state.text2,
      text3: this.state.text3,
      text4: this.state.text4,
      title: this.state.title,
      url: this.state.url,
      urltext: this.state.urltext,
      url2: this.state.url2,
      urltext2: this.state.urltext2,
      createdAt: this.state.curTime,
      image:this.state.image.name,
      category:this.state.category,
    });

    let storageRef = this.props.firebase.storage1().ref(`/images/${this.state.image.name}`);

storageRef.put(this.state.image)
.then(function(snapshot) {
 alert("送信されました");
});

    this.setState({ text1: '' });
    this.setState({ text2: '' });
    this.setState({ text3: '' });
    this.setState({ text4: '' });
    this.setState({ title: '' });
    this.setState({ url: '' });
    this.setState({ urltext: '' });
    this.setState({ url2: '' });
    this.setState({ urltext2: '' });
    this.setState({ image: '' });
    this.setState({ category: '' });

    event.preventDefault();

  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text,
      editedAt: this.state.curTime,
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForMessages,
    );
  };

  render() {
    const { text1, text2, text3, text4, title, url, urltext, url2, urltext2, messages, loading, category } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="margin-submit">
            {loading && <div>Loading ...</div>}
            <div className="">
            <h2 className="content-title-black2">お知らせ</h2>
            <div className="title-underline"></div>
                        {messages && (
                          <MessageList
                            authUser={authUser}
                            messages={messages}
                            onEditMessage={this.onEditMessage}
                            onRemoveMessage={this.onRemoveMessage}
                          />
                        )}

                        {!messages && <div>There are no messages ...</div>}

            </div>
<div className="white-board">
            <form
              onSubmit={event =>
                this.onCreateMessage(event, authUser)
              }
            >

            <div>
            <div>タイトル(13文字以内)</div>
            <input
              className="title-main"
              type="text"
              value={title}
              onChange={this.onChangeTitle}
              maxLength='13'
            />
            </div>

            <div>
            <div>カテゴリー（記事、お知らせ）</div>
            <label>記事</label>
                            <input type="radio" name="記事" value="記事" checked={this.state.category === '記事'}
                                   onChange={() => this.setState({category: '記事'})}/> <br />
                            <label>お知らせ</label>
                            <input type="radio" name="お知らせ" value="お知らせ" checked={this.state.category === 'お知らせ'}
                                   onChange={() => this.setState({category: 'お知らせ'})}/>
            </div>

            <div>
              <div>本文テキスト1</div>
              <textarea
              className="text-main"
                value={text1}
                onChange={this.onChangeText1}
              />

              <div>本文テキスト2</div>
              <textarea
              className="text-main"
                value={text2}
                onChange={this.onChangeText2}
              />
              <div>本文テキスト3</div>
              <textarea
              className="text-main"
                value={text3}
                onChange={this.onChangeText3}
              />
              <div>本文テキスト4</div>
              <textarea
              className="text-main"
                value={text4}
                onChange={this.onChangeText4}
              />
              </div>
              <div>
                <div>url</div>
                <input
                className="text-main"
                  type="text"
                  value={url}
                  onChange={this.onChangeUrl}
                />
                </div>
                <div>
                  <div>urlテキスト</div>
                  <input
                  className="text-main"
                    type="text"
                    value={urltext}
                    onChange={this.onChangeUrltext}
                  />
                  </div>
                  <div>
                    <div>url2</div>
                    <input
                    className="text-main"
                      type="text"
                      value={url2}
                      onChange={this.onChangeUrl2}
                    />
                    </div>
                    <div>
                      <div>urlテキスト2</div>
                      <input
                      className="text-main"
                        type="text"
                        value={urltext2}
                        onChange={this.onChangeUrltext2}
                      />
                      </div>
              <div>
                <div>画像</div>
                <input
                  type="file"
                  onChange={this.onChangeImage}
                />
                </div>
              <div>
              <button type="submit">Send</button>
              </div>
            </form>
          </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);
