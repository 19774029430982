import React, { Component } from 'react';
// import { AuthUserContext } from '../Session';
// import { withFirebase } from '../Firebase';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

class MessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      baseImg:this.props.message.image,
      recieveImg: '',
      visible: false,
      mainMessage: '',
      device: 'pc',
    };
// this.getImage (this.state.baseImg);
  }

  componentDidMount() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
          this.setState({ device: 'sp' });
      } else {
          this.setState({ device: 'pc' });
      }
  }

//   firebaseInit = () => {
//     if (this.props.firebase && !this._initFirebase) {
//       this._initFirebase = true;
// console.log(this.props.firebase.storage1());
//       // this.onListenForMessages();
//       this.getImage (this.state.baseImg);
//     }
//   };

// componentDidMount() {
//   this.blankSpace();
// }
//
// blankSpace = () => {
//     const fixedText= this.props.message.text.replaceAll('\\n', '\n');
//     this.setState({ mainMessage: fixedText });
// };

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.message.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditMessage(this.props.message, this.state.editText);

    this.setState({ editMode: false });
  };

  show() {
      this.setState({ visible: true });
  }

  hide() {
      this.setState({ visible: false });
  }

  render() {
    const { authUser, message, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
      <div className="noticeDiv">

          <div className="noticeCard" onClick={this.show.bind(this)}>

            <div className="noticeText">
            {message.category =="記事" && (
            <div className="noticeCategoryNews">{message.category}</div>
          )}
          {message.category =="お知らせ" && (
          <div className="noticeCategoryNotice">{message.category}</div>
        )}
            {this.state.device =="pc" && (
            <div className="noticeCreatedAt">{message.createdAt}</div>
            )}
            {this.state.device =="pc" && (
            <div className="noticeTitle">{message.title}</div>
            )}

            {this.state.device =="sp" && (
              <div className="noticecolumn">
              <div className="noticeTitle">{message.title}</div>
              <div className="noticeCreatedAt">{message.createdAt}</div>
              </div>
            )}

            </div>
            <button
              type="button"
              onClick={() => onRemoveMessage(message.uid)}
              className="deletebutton"
            >
              Delete
            </button>

          </div>

          <Rodal animation="slideUp" visible={this.state.visible} onClose={this.hide.bind(this)}>
<img className="rodal-notice-Img" src={ message.image } alt={message.text} />
              <h3 className="notice-textsize">{message.title}</h3>
              <div className="notice-main-text">
              <div>{message.text1}</div>
              <div>{message.text2}</div>
              <div>{message.text3}</div>
              <div>{message.text4}</div>

              {message.urltext && <div className="noticeUrl">
              <a className="noticeTextUrl" href={message.url} target="_blank" rel="noopener">{message.urltext}</a></div>
              }

              {message.urltext2 && <div className="noticeUrl">
              <a className="noticeTextUrl" href={message.url2} target="_blank" rel="noopener">{message.urltext2}</a></div>
              }
              </div>
              <div className="noticeDate">{message.createdAt}</div>
          </Rodal>
      </div>
    );
  }
}

export default MessageItem;
