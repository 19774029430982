import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import GamesList from './GamesList';
// import 'firebase/storage';

// import { firestore } from './plugins/firebase';

class Games extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      date: '',
      startTime: '',
      kScore: '',
      aScore: '',
      against: '',
      place: '',
      gameName: '',
      gameInfo: '',
      loading: false,
      messages: [],
      limit: 5,
      curTime : new Date().toLocaleString(),
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.props.firebase
      .games()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const messageObject = snapshot.val();

        if (messageObject) {
          const messageList = Object.keys(messageObject).reverse().map(key => ({
            ...messageObject[key],
            uid: key,
          }));

          this.setState({
            messages: messageList,
            loading: false,
          });

        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.games().off();
  }

  onChangeDate = event => {
    this.setState({ date: event.target.value });
  };

  onChangeStartTime = event => {
    this.setState({ startTime: event.target.value });
  };

  onChangeAgainst = event => {
    this.setState({ against: event.target.value });
  };

  onChangeKScore = event => {
    this.setState({ kScore: event.target.value });
  };

  onChangeAScore = event => {
    this.setState({ aScore: event.target.value });
  };

  onChangePlace = event => {
    this.setState({ place: event.target.value });
  };

  onChangeGameName = event => {
    this.setState({ gameName: event.target.value });
  };

  onChangeGameInfo = event => {
    this.setState({ gameInfo: event.target.value });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.games().push({
      date: this.state.date,
      startTime: this.state.startTime,
      against: this.state.against,
      kScore: this.state.kScore,
      createdAt: this.state.curTime,
      aScore:this.state.aScore,
      place:this.state.place,
      gameName:this.state.gameName,
      gameInfo:this.state.gameInfo,
    });

//     let storageRef = this.props.firebase.storage1().ref(`/images/${this.state.image.name}`);
//
// storageRef.put(this.state.image)
// .then(function(snapshot) {
//  alert("送信されました");
// });

    this.setState({ date: '' });
    this.setState({ startTime: '' });
    this.setState({ against: '' });
    this.setState({ kScore: '' });
    this.setState({ aScore: '' });
    this.setState({ place: '' });
    this.setState({ gameName: '' });
    this.setState({ gameInfo: '' });

    event.preventDefault();

  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.game(message.uid).set({
      ...messageSnapshot,
      text,
      editedAt: this.state.curTime,
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.game(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForMessages,
    );
  };

  render() {
    const { date, startTime, place, against, kScore, aScore, gameName, gameInfo, messages, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="margin-submit">
            {loading && <div>Loading ...</div>}
            <div className="game-page">
            <h2 className="content-title-white">試合情報</h2>
            {messages && (
              <GamesList
                authUser={authUser}
                messages={messages}
                onEditMessage={this.onEditMessage}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!messages && <div>There are no messages ...</div>}

</div>
<div className="white-board">
            <form
              onSubmit={event =>
                this.onCreateMessage(event, authUser)
              }
            >
            <div>
              <div>試合日</div>
              <input
              className="text-main"
                value={date}
                onChange={this.onChangeDate}
              />
            </div>

            <div>
              <div>試合時間</div>
              <input
              className="text-main"
                value={startTime}
                onChange={this.onChangeStartTime}
              />
            </div>

            <div>
              <div>会場</div>
              <input
              className="text-main"
                value={place}
                onChange={this.onChangePlace}
              />
            </div>

            <div>
              <div>大会名</div>
              <input
              className="text-main"
                value={gameName}
                onChange={this.onChangeGameName}
              />
            </div>

            <div>
              <div>試合名</div>
              <input
              className="text-main"
                value={gameInfo}
                onChange={this.onChangeGameInfo}
              />
            </div>

            <div>
              <div>試合相手</div>
              <input
              className="text-main"
                value={against}
                onChange={this.onChangeAgainst}
              />
            </div>

            <div>
              <div>久我山得点</div>
              <input
              className="text-main"
                value={kScore}
                onChange={this.onChangeKScore}
              />
            </div>

            <div>
              <div>相手得点</div>
              <input
              className="text-main"
                value={aScore}
                onChange={this.onChangeAScore}
              />
            </div>

              <div>
              <button type="submit">Send</button>
              </div>
            </form>
          </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Games);
