import React, { Component } from 'react';
// import { AuthUserContext } from '../Session';
// import { withFirebase } from '../Firebase';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

class GamesItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      baseImg:this.props.message.image,
      recieveImg: '',
      visible: false,
      mainMessage: '',
    };
// this.getImage (this.state.baseImg);
  }

//   firebaseInit = () => {
//     if (this.props.firebase && !this._initFirebase) {
//       this._initFirebase = true;
// console.log(this.props.firebase.storage1());
//       // this.onListenForMessages();
//       this.getImage (this.state.baseImg);
//     }
//   };

// componentDidMount() {
//   this.blankSpace();
// }
//
// blankSpace = () => {
//     const fixedText= this.props.message.text.replaceAll('\\n', '\n');
//     this.setState({ mainMessage: fixedText });
// };

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.message.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditMessage(this.props.message, this.state.editText);

    this.setState({ editMode: false });
  };

  show() {
      this.setState({ visible: true });
  }

  hide() {
      this.setState({ visible: false });
  }

  render() {
    const { authUser, message, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
            <div className="game-content">
            <div className="game-date">{message.date}<span className="game-date-time">{message.startTime}</span></div>
            <div className="game-title">{message.gameName}<div>{message.gameInfo}</div></div>
            <div className="game-score-center">
            <div className="game-score">{message.kScore} - {message.aScore}</div>
            </div>
            <div className="game-match">
            <div className="game-match-name">國學院大學久我山高校</div>
            <div className="game-match-sub">VS</div>
            <div className="game-match-name">{message.against}</div>
            </div>
            <div className="game-score-center">
            <div className="game-match-place">＠{message.place}</div>

            <button
              type="button"
              onClick={() => onRemoveMessage(message.uid)}
            >
              Delete
            </button>
          </div>
      </div>
    );
  }
}

export default GamesItem;
